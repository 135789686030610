<template>
  <button class="btn p-relative"
          :class="{disabled, loading}"
          @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {}
    }
  }
</script>
